// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-blog-list-template-js": () => import("C:\\Users\\david\\devpage\\src\\components\\BlogListTemplate.js" /* webpackChunkName: "component---src-components-blog-list-template-js" */),
  "component---src-components-blog-template-js": () => import("C:\\Users\\david\\devpage\\src\\components\\BlogTemplate.js" /* webpackChunkName: "component---src-components-blog-template-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\david\\devpage\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("C:\\Users\\david\\devpage\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\david\\devpage\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("C:\\Users\\david\\devpage\\src\\pages\\projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

